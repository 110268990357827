// Try to keep the keys in this translation file in ascending alphabetical order in order to quickly find translations
export default {
	alert: {
		changed_order_out_of_sync:
			'Please note that this order may not display the most recent booking data, because we have changed your order manually at your request. No worries, we have your most recent booking data in our booking system and in this shop you will find all possible options that are suitable for your transport and accommodation package.',
		check_email: 'Check your emailaddress',
		complete_order_in_time: 'Be sure to complete your order before the timer runs out.',
		complete_payment_in_time: 'Be sure to complete your payment before the timer runs out.',
		no_stock: 'This item is out of stock. You can select an alternative.',
		no_refund_choice: 'You have not yet made a choice with regard to booking protection.',
		no_checkout: 'You cannot checkout yet because:',
		sold_out: 'This item is sold out. You can select an alternative.',
		sold_out_combination: 'This package combination is sold out.'
	},
	checkout: {
		confetti: 'More confetti!',
		cta: 'Ready to order',
		entertainment_tax: {
			lead: 'The city of Amsterdam implemented an Entertainment Tax. If you make use of transport or entertainment as a paying customer, you have to pay an entertainment tax (vermakelijkhedenretributie, vmr). From 1 January 2023, the event entertainment tax (vmr) will apply to paid events and festivals organised in public spaces. Visitors pay €1.50 each festival day to the event organiser.',
			title: 'Local Entertainment Tax'
		},
		included_vat: 'INCL. VAT & SERVICE COSTS',
		lead: 'Please check the content of your cart before completing your order.',
		lead_complete: 'Fill in your personal details.',
		lead_upgrade: 'Below you will find an overview of your order.',
		payment_method: {
			select: 'Select a payment method',
			loading: 'Please hold on, checking available payment methods...',
			method: 'Payment method',
			zero: 'Select "no payment required" to complete your order.',
			discount_next_step: 'Your discount is deducted from the total amount in the next step'
		},
		insurance: {
			addon: 'Add refund protection to your new add-ons for just',
			add: 'Add booking protection for all your tickets for just',
			default: 'Add refund protection for just',
			extend: 'Extends the restitution protection for your upgrade for',
			upgrade: 'Add refund protection to your upgrade for just',
			xcover: {
				uri: '<a href="https://www.xcover.com/en/pds/booking-refund-protection-eu" target="_blank">Find Complete Coverage details</a>',
				body: "You're covered if you can't attend for medical reasons (including COVID-19), due to emergency services requiring you to be at home or if authorities issue a travel warning. Refund protection is carried out by our partner XCover."
			}
		},
		order_fee: 'Order service fee',
		price: 'Price',
		service_fee: 'Ticket service fee',
		shipping_costs: {
			explainer: 'There are extra costs for shipping (some) of your items.',
			label: 'Calculate shipping costs'
		},
		subtotal: 'Subtotal',
		tax: 'VAT',
		terms: {
			preregistration: `By clicking the button 'Save Wishlist' you will create a wishlist and you accept the {termsConditions} and confirm that you have read the {privacyPolicy}. By accepting you agree to the general terms and conditions of our {paymentProvider} partner.]`,
			default: `By clicking the button 'Ready to order' you will create an order and you accept the {termsConditions} and confirm that you have read the {privacyPolicy}. By accepting you agree to the general terms and conditions of our {paymentProvider} partner.]`
		},
		title: 'Your Cart',
		total: 'Total',
		tourist_tax: {
			lead: 'This is a local tax imposed by some municipalities and cities, which is used to finance and maintain local facilities. The amount will vary depending on municipality and often is a small amount per person per night. In this case it will be € per person per night',
			title: 'Tourist Tax'
		},
		upsell: {
			cta: 'More products',
			lead: 'Add selected items to your caet',
			title: 'Others have bought'
		},
		voucher: {
			add: 'Add voucher code',
			invalid: 'This voucher code is invalid',
			used: 'This voucher code has already been used',
			redeem: 'Redeem voucher',
			success: 'Voucher redeemed successfully!'
		}
	},
	error: {
		accommodation_sold_out:
			'The selected items cannot be added.This accommodation is sold out. People are now completing their booking. If the payments are not confirmed, this package will be available again.',
		accommodation_ticket_sold_out:
			'The selected items cannot be added. The tickets are currently not available. If payments are not confirmed, this ticket will be available again.',
		voucher_invald: 'This voucher code is invalid',
		voucher_user: 'This voucher code has already been used',
		voucher_redeem_error: 'An error occurred while redeeming the voucher. Please try again.',
		form_covid_not_accepted: 'You have not yet agreed with the COVID refund conditions.',
		form_has_errors: 'Please fix the errors in the form to complete your order.',
		form_incomplete: 'Please fill in all required fields *',
		form_no_country_selected: 'You have not yet selected a country to calculate the shipping costs.',
		form_no_insurance_choice: 'Please state whether you want to add a booking protection for your order.',
		form_terms_not_accepted: 'Please accept the terms and conditions to complete your order.',
		generic: 'Try again. Refresh this page and, if necessary, join the queue to access the ticket shop again.',
		invalid_request: 'An invalid request has been made. Please try again.',
		no_config_found: 'An OSS Configuration is missing. Please contact the support.',
		order_lookup_failed:
			'When trying to find your order, an error occurred. Something went wrong on our side. Try it again later',
		order_lookup_invalid: 'Your order number seems invalid, copy the correct number from the Paylogic Mail. ',
		order_lookup_no_order:
			'We cannot find an order with that order number.Copy the correct order number from the Paylogic Mail.',
		page_not_found: 'Page not found.',
		product_sold_out: 'This product is sold out.',
		shipping_unavailable: 'Shipping is currently unavailable to your location.',
		undefined: 'An unknown error has occurred.',
		code: {
			0: '--modal-closed--',
			1000: {
				title: 'Oeps...',
				message: 'Something went wrong. Please try again.'
			},
			2000: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			2001: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			2002: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: 'CONTINUE'
			},
			3000: {
				title: 'Registration error',
				message:
					"There's no registration linked to this {brand} account. Please contact info@q-dance.com for more information."
			},
			3001: {
				title: 'Registration error',
				message: 'An order has already been placed with this account'
			},
			3002: {
				title: 'Registration error',
				message: 'Registration is closed.'
			},
			3003: {
				title: 'Not Allowed',
				message: 'The shop is not open for you yet. Please check your timeslot and try again.'
			},
			3005: {
				title: 'OOPS... YOU ARE ALREADY DEDIQATED',
				message: 'You can only purchase one membership per account.'
			},
			4000: {
				title: 'The Shop is closed'
			},
			4001: {
				title: 'Thank you',
				message: 'Your wishlist has been successfully saved!'
			},
			4002: {
				title: 'Upgrade error',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4003: {
				title: 'Upgrade error',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4004: {
				title: "Time's up",
				message:
					'Due to heavy traffic you can only browse for a limited time. You can re-enter the shop by clicking the link in your e-mail.',
				label_cancel: false
			},
			4005: {
				title: 'No valid order id',
				message:
					'Order already processed or not found. If you believe this is an error, click on the email link again.'
			},
			4006: {
				title: 'No valid access id',
				message: 'You need a valid access id to enter the shop. Try clicking the link in the email again.'
			},
			5000: {
				title: 'Product sold out'
			},
			5001: {
				title: 'Product not found'
			},
			5002: {
				title: 'No travel package(s) found',
				message: 'Please make a different selection'
			},
			5003: {
				title: 'Shipment not Available',
				message:
					'The selected product(s) has/have automatically been removed from your cart. Unfortunately, it is not possible to ship merchandise to the country you have selected.<br>For any questions, contact us at <a href="mailto:merchandise@q-dance.com">merchandise@q-dance.com</a>'
			},
			6000: {
				title: 'Change registration',
				message:
					'Please note that you need to reselect your type of registration (Travel & Stay Packages or ticket only) to enter the member sale. Please complete your registration again at the next step.',
				label_cancel: 'Cancel'
			},
			6001: {
				title: 'Remove item',
				message: 'Are you sure you want to remove this item?',
				label_cancel: 'Cancel'
			},
			6002: {
				title: "Time's up",
				message:
					"It looks like it took too much time to complete your order. Don't worry, you can try again by clicking the button below. Make sure to complete your order within 7 minutes though!",
				label_cancel: false,
				label_confirm: 'Try again'
			},
			6003: {
				title: 'No ticket(s) selected',
				message:
					'Your order contains an accommodation without entrance tickets. Keep in mind that entrance to {festivalId} is only possible with an entrance ticket. Are you sure that you want to continue with your purchase?',
				label_cancel: false,
				label_confirm: 'Continue'
			},
			6004: {
				title: 'Welcome to the {brand} Shop',
				message: 'Login / create an account to get started.',
				label_cancel: false,
				label_confirm: 'Login'
			},
			6005: {
				title: 'Dediqated Member Sale',
				message: '<p>You need a Dediqated Membership to enter this shop.</p>',
				label_cancel: false,
				label_confirm: 'Shop'
			},
			6010: {
				title: 'Are you sure?',
				message: '<p>Are you sure you want to cancel your order?</p>',
				label_cancel: 'No',
				label_confirm: 'Yes'
			},
			7000: {
				title: 'Attention',
				message:
					'The Travel & Stay member sale starts on February 29th. If you want to get acces to it, you will need to change your registration to Travel & Stay. All remaining packages will also go on sale during the ticket member sale on March 7th.'
			},
			8000: {
				title: 'Attention',
				message: ''
			},
			8001: {
				title: 'Attention',
				message: '',
				label_cancel: false
			},
			8002: {
				title: 'An error has occured',
				message: '',
				label_cancel: false
			},
			8003: {
				title: '',
				message: '',
				label_cancel: 'CONTINUE'
			}
		}
	},

	form: {
		address_information: 'Address information',
		birthday: 'Date of birth *',
		city: 'City *',
		country: {
			label: 'Country *'
		},
		countrycode: {
			label: 'Country code *'
		},
		day: 'Day',
		email: 'Email address *',
		extension: 'Number suffix',
		filter: 'Choose a filter',
		filter_explain: 'Find what you need by filtering through one of the categories below.',
		firstname: 'First name *',
		gender: {
			female: 'Female',
			label: 'Gender *',
			male: 'Male',
			other: 'Other'
		},
		lastname: 'Last name *',
		month: 'Month',
		personal_information: 'Personal information',
		phonenumber: 'Phone number *',
		postalcode: 'Postal code *',
		select: 'Make a selection',
		shipping_information: 'Shipping information',
		state: 'State *',
		select_arrival_date: 'Select arrival date',
		streetname: 'Street Name *',
		streetnumber: 'Street Number *',
		year: 'Year'
	},
	label: {
		back: 'Back',
		buy: 'Buy',
		cancel: 'Cancel',
		cart: {
			add: 'Add',
			added: 'Added',
			add_to_cart: 'Add to cart',
			add_to_wishlist: 'Add to wishlist',
			add_to_selection: 'Add to selection',
			add_x_to_cart: 'Add {x} tickets to your cart',
			add_x_to_wishlist: 'Add {x} tickets to your wishlist',
			cart: 'Cart',
			complete_payment: 'Complete payment',
			complete_order: 'Complete Order',
			continue_shopping: 'Continue shopping',
			order: 'Ready to order',
			remove: 'Remove',
			remove_from_cart: 'Remove from cart',
			remove_from_wishlist: 'Remove from wishlist',
			replace_item: 'Replace',
			pay: 'Pay',
			save: 'Save pre-registration',
			sold_out: 'This product is sold out',
			wishlist: 'Wishlist'
		},
		clear: 'Clear',
		close: 'Close',
		complete_your_experience: 'Complete your experience',
		confirm: 'Confirm',
		continue: 'Continue',
		continue_shopping: 'Continue shopping',
		filter: 'Filter',
		finalize: 'Complete your order',
		find_order: 'Find my order',
		extras: 'extras',
		in_stock: 'In stock',
		including: 'including',
		go_to_checkout: 'Go to checkout',
		go_to_wishlist: 'Go to wishlist',
		merchandise: 'merchandise',
		more_info: 'More info',
		navigation: {
			back: 'Go Back',
			checkout: 'Go to checkout',
			next: 'Next',
			overview: 'Overview',
			previous: 'Previous',
			wishlist: 'Go to wishlist'
		},
		newsletter_signup: 'Sign up for the newsletter',
		no: 'No',
		no_items: 'No items found',
		order: 'Order',
		order_number: 'Your order number',
		reset: 'Reset',
		return_to_homepage: 'Return to the homepage',
		sold_out: 'Sold Out',
		shop: {
			members_sale: 'TICKET SHOP',
			preregistration_sale: 'PRE-REGISTRATION',
			regular_sale: 'TICKET SHOP',
			travel_sale: 'TRAVEL SHOP',
			wishlist: 'WISHLIST SHOP'
		},
		ticket: 'tickets',
		transportation: 'transportation',
		travel: 'travel',
		try_again: 'Try again',
		update: 'Update',
		yes: 'Yes'
	},

	included: 'included',
	loading: 'Loading...',
	loading_one_moment: 'One moment please...',
	modal: {
		save_time: {
			body: 'Login in now and save time during checkout',
			cta: 'Login or Register',
			title: 'Save Time'
		}
	},
	page: {
		checkout: {
			lead: 'You have 5 minutes to complete your payment.',
			title: 'Select your payment method'
		},
		compare_transport: {
			lead: '',
			filter_title: 'Find your transport',
			title: 'organised Bus Transport'
		},
		extras: {
			lead: 'Select your additional products such as parking cards, lockers and other experiences.',
			filter_title: 'Extras & other experiences',
			title: 'Extras & other experiences'
		},
		home: {
			lead: 'Welcome, please choose an event to begin.',
			title: '{brand} One Stop Shop'
		},
		membership: {
			disclaimer_step:
				'The DEDIQATED Membership is a recurring membership. You will be charged for 1-year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			membership_includes: 'Membership includes',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
			disclaimer_checkout:
				'You are about to commit to a 1-year Membership. You agree that Q-dance will charge you for 1 year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			lead: '',
			title: 'MEMBERSHIP'
		},
		membership_monthly: {
			disclaimer_checkout:
				'You are about to commit to a 1-month Membership. You agree that Q-dance will charge you for 1 month in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			disclaimer_step:
				'The DEDIQATED Membership is a recurring membership. You will be charged for 1-month in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
			membership_includes: 'Membership includes',
			lead: '',
			title: 'MEMBERSHIP'
		},
		merchandise: {
			lead: 'Select your merchandise.',
			one_size: 'One size fits all',
			filter_title: 'Merchandise',
			title: 'Merchandise'
		},
		payment_status: {
			canceled: {
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the queue.",
				title: 'Payment cancelled'
			},
			completed: {
				lead: '',
				message:
					"Thank you for your order. We are currently processing your order and payment. This is not a confirmation! Your order will be confirmed after you have received a confirmation e-mail. If you don't receive a confirmation e-mail within 2 hours, please check your bank account or credit card to see if the payment was successful.",
				title: 'Thank you'
			},
			error: {
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the waiting list.",
				title: 'Payment failed'
			},
			failed: {
				lead: '',
				message:
					"Unfortunately, something went wrong with your payment and your order wasn't successful. It's possible to enter the shop again, but please note that you'll be placed at the back of the waiting list.",
				title: 'Payment failed'
			},
			pending: {
				lead: '',
				message:
					"<p>Thanks for your order. We are still waiting for final confirmation of your payment by the payment provider. Don't worry, normally we get the confirmation within a couple of minutes. <br /><br /><strong>This will happen next</strong><br />Your order and payment will be processed. <br />You will receive a confirmation e-mail. <br />You will receive a tracking code as soon as we ship your order (if applicable)<br />If the money is deducted from your bank account and you do not get a confirmation within 2 hours, please contact us.</p>",
				title: 'Payment is pending'
			},
			newsletter: 'Sign up for the newsletter and be the first to know about important announcements.',
			payment_provider: {
				adyen: {
					message:
						"If you have any questions concerning your payment or order, please contact <a target='_blank' href='https://www.justbleep.it/'>Bleep Customer Support</a>."
				},
				paylogic: {
					message:
						"If you have any questions concerning your payment or order, please contact <a target='_blank' href='https://customerservice.paylogic.com/'>Paylogic Customer Support</a>."
				}
			}
		},
		shop: {
			experience_prebuilder: {
				form: ['I am interested in', 'I will travel by', 'How many persons?'],
				lead: "Click on 'Tickets Only ' to order tickets. Are you going for the all-in-one experience including accommodations? Click on 'Travel & Stay Packages' for all travel and accommodation packages.",
				title: 'Create your experience',
				preregistration: {
					lead: 'You can now add products such as Tickets, Travel & Stay Packages, Merchandise and lockers to your wishlist. Your wishlist can be saved to your account.',
					title: '{festival} wishlist'
				}
			},
			experience_builder: {
				choose: 'Choose your',
				select: 'Select your',
				comparetransport: {
					label: 'Transportation',
					remove: 'Remove',
					update: 'Change/Add'
				},
				extras: {
					label: 'Extras & other experiences',
					remove: 'Remove',
					update: 'Change/Add'
				},
				lead: 'Which products would you like to add to your cart?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Remove',
					update: 'Change/Add'
				},
				ticket: {
					label: '{event} Tickets',
					remove: 'Remove',
					update: 'Change/Add'
				},
				title: 'Your cart',
				transportation: {
					label: 'Transportation',
					remove: 'Remove',
					update: 'Change/Add'
				},
				travel: {
					label: 'Travel & Stay Packages',
					nights: 'night | nights',
					persons: 'person | persons',
					remove: 'Remove',
					update: 'Update Booking'
				},
				preregistration: {
					save: {
						lead: "During the {festival} sale, you can add your complete wishlist to your shopping cart. By doing so, you can immediately order all your preferred products at once. Keep in mind that certain products might already be sold out as the wishlist doesn’t guarantee you of any products.<br/><br/>It’s possible to make changes to your wishlist as often as you want during the registration period. The registration period ends on February 28th at 23:59 (CET). <br/><br/>Questions about your registration: <a href='mailto:info@q-dance.com'>info@q-dance.com</a>",
						title: 'Wishlist completed?'
					}
				}
			},
			lead: 'Everything you need in one shop.',
			title: 'Shop'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'You can now add products such as lockers, parking tickets, travel and accommodation packages and merchandise to your shopping basket.',
				title: "{event} extra's shop"
			},
			lead: 'An overview of your previous order(s).',
			loading: {
				title: 'Checking your previous order(s)...',
				lead: 'We are looking up your previous order (s). Do not refresh this page, loading your previous order (s) can take up to a minute.'
			},
			no_order: {
				lead: 'Enter the order number that you have received in your email (the order number you received from the main booker). Make sure you are logged in with the same account which was used to place your order.',
				title: 'No previous orders have been found.'
			},
			title: 'Your purchased product(s).'
		},
		old: {
			package: {
				addons: 'Je gekozen uitbreidingen in het winkelmandje',
				summary: 'Samenvatting van je gekozen pakket'
			},
			changedorder:
				'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
			referenceLabel: 'Jouw ordernummer'
		},
		tickets: {
			filter_explain: 'Filter entrance tickets',
			includes: 'Ticket includes',
			lead: 'Select your entrance tickets.',
			remove: 'Remove tickets',
			select: 'Select your tickets',
			sold_out: 'Sold Out',
			sold_out_travel: 'Travel Packages',
			sold_out_combo: 'Tickets are sold out! A limited amount can still be available in combination with {link}',
			title: 'Tickets',
			upsell_accommodations: 'Accommodation packages are available for you to add to your order.',
			upsell_extras: 'Extra products are available for you to add to your order.',
			upsell_travel: 'Travel packages are available for you to add to your order.'
		},
		transportation: {
			add: 'Add',
			arrival: 'Arrival',
			choices: 'Select your region',
			departure_time: '*estimated departure times',
			disclaimer: '*All organised buses travel to',
			disclaimer2: 'festivalentrance.',
			eu: 'Europe',
			fee: '*excl. €5,- service fee per order',
			filter_title: 'Find your transportation',
			from: 'From',
			lead: 'Select your organised bus transport.',
			netherlands: 'The Netherlands',
			remove: 'Remove',
			select_other: 'Please select a different option.',
			sold_out: 'Sold Out',
			tickets: 'Bus tickets',
			title: 'Transportation',
			to: 'To'
		},
		travel: {
			accommodation_extras: "Accommodatie extra's",
			arrival_date_time: 'Arrival day and time',
			arrival_date: 'Arrival date',
			available: 'Available',
			category_extras: 'Extras',
			category_merchandise: 'Merchandise',
			category_transfer: 'Transfers',
			create_package: 'Create your package',
			departure_date: 'Departure date',
			filter_title: 'Find your accommodation',
			includes: 'Package includes',
			lead: 'Select your travel and stay packages.',
			make_selection: 'Maak je selectie',
			no_packages: 'No Package(s) found',
			no_ticket: 'No Ticket(s)',
			not_available: 'Not available',
			number_of_nights: 'Number of nights',
			package_price: 'Travel package price',
			persons: 'Number of persons',
			room_setup: 'Choose your room setup',
			select_other: 'Please select a different option.',
			selected: 'Selected',
			sold_out: 'Sold Out',
			tickets: 'Tickets',
			title: 'Travel & Stay Packages',
			try_again: 'Try again',
			still_available: 'Pakketten nog beschikbaar',
			warning: {
				title: 'Warning',
				message:
					'You already have tickets in your shopping cart. Are you sure you want to add extra tickets to your travel package?'
			}
		}
	},
	remaining: 'overig',
	savetimeModal: {
		cta: 'Login or registr',
		body: 'Login and save time when purchasing tickets.',
		title: 'Save Time'
	},
	time: {
		hour: 'hour | hours',
		hour_abbr: 'h',
		minute: 'minute | minutes',
		minute_abbr: 'min',
		second: 'second | seconds',
		second_abbr: 's'
	},
	wish_list: {
		checkout: {
			add_all: 'Add all items',
			add_wishlist_products: 'Add product(s) first',
			calculate: 'Calculate shipping costs',
			cta: 'Go to your wishlist',
			cta_alternative: 'Your Cart',
			price: 'Price',
			save: {
				lead: 'During the sale, you can add your complete wishlist to your shopping cart. By doing so, you can immediately order all your preferred products at once. Keep in mind that certain products might already be sold out as the wishlist doesn’t guarantee you of any products.<br/><br/>It’s possible to make changes to your wishlist as often as you want during the registration period.',
				title: 'Save wishlist'
			},
			shipping_costs: {
				explainer: 'There are extra costs for shipping (some) of your items.',
				label: 'Calculate shipping costs'
			},
			title: 'Your Wishlist',
			total: 'Total'
		},
		experience_builder: {
			choose: 'Choose your',
			select: 'Select your',
			comparetransport: {
				label: 'Transportation',
				remove: 'Remove',
				update: 'Change/Add'
			},
			extras: {
				label: 'Extras & other experiences',
				remove: 'Remove',
				update: 'Change/Add'
			},
			merchandise: {
				label: 'Merchandise',
				remove: 'Remove',
				update: 'Change/Add'
			},
			ticket: {
				label: '{event} Tickets',
				remove: 'Remove',
				update: 'Change/Add'
			},
			title: 'Your cart',
			transportation: {
				label: 'Transportation',
				remove: 'Remove',
				update: 'Change/Add'
			},
			travel: {
				label: 'Travel & Stay Packages',
				nights: 'night | nights',
				persons: 'person | persons',
				remove: 'Remove',
				update: 'Update Booking'
			},
			title: 'Your Wishlist',
			lead: 'Which products would you like to add to your wishlist?'
		},
		lead: 'You can now check if your preferred tickets and other items from your wishlist are still available for purchase. Make sure to add them to your cart if they are still available. Product(s) sold out? Don’t worry, you can easily select an alternative by clicking the “replace” button.',
		loading: {
			lead: "We're retrieving your saved wishlist. Don't refresh this page, loading your wishlist might take up to one minute.",
			title: 'Checking your wishlist'
		},
		preregistration: {
			title: 'Your Wishlist',
			lead: 'Fill your wishlist and save time for the sale.'
		},
		title: 'Your Wishlist'
	}
};
