// Try to keep the keys in this translation file in ascending alphabetical order in order to quickly find translations
export default {
	alert: {
		changed_order_out_of_sync:
			'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
		check_email: 'Controlleer je e-mailadres',
		complete_order_in_time: 'Zorg ervoor dat u uw bestelling voltooit voordat de timer oploopt.',
		complete_payment_in_time: 'Zorg ervoor dat u uw betaling voltooit voordat de timer opraakt.',
		no_stock: 'Dit artikel is niet op voorraad.U kunt een alternatief selecteren.',
		no_refund_choice: 'U hebt nog geen keuze gemaakt met betrekking tot boekingsbescherming.',
		no_checkout: 'Je kunt nog niet afrekenen omdat:',
		sold_out: 'Dit item is uitverkocht.U kunt een alternatief selecteren.',
		sold_out_combination: 'Deze pakketcombinatie is uitverkocht.'
	},
	checkout: {
		confetti: 'Meer confetti!',
		cta: 'Ga naar betalen',
		entertainment_tax: {
			lead: 'Vermaakbelasting, ook wel entertainmentbelasting genoemd, is een belasting die wordt geheven op verschillende vormen van vermaak of amusement, zoals bioscoopkaartjes, concerten, pretparken, sportevenementen of andere publieke vormen van entertainment.  De opbrengst gaat naar de overheid.',
			title: 'Borg'
		},
		included_vat: 'Incl. BTW & servicecosts',
		lead: 'Controleer de inhoud van uw winkelwagen voordat u uw bestelling voltooit.',
		lead_complete: 'Vul uw persoonlijke gegevens in.',
		lead_upgrade: 'Hieronder vindt u een overzicht van uw bestelling.',
		payment_method: {
			select: 'Selecteer een betaalmethode',
			loading: 'Een moment, we zijn even de beschikbare betaalmethoden aan het controleren ...',
			method: 'Betaalmethode',
			zero: 'Selecteer "Geen betaling vereist" om uw bestelling te voltooien.',
			discount_next_step: 'Uw korting wordt in de volgende stap afgetrokken van het totale bedrag'
		},
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			add: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			default: 'Voeg boekingsbescherming toe voor slechts',
			extend: 'Verlengt de restitutiebescherming voor uw upgrade voor',
			upgrade: 'Voeg boekingsbescherming toe voor je upgrade voor slechts',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		order_fee: 'Bestelkosten',
		price: 'Prijs',
		service_fee: 'Ticket Service Fee',
		shipping_costs: {
			explainer: 'Het verzenden van (sommige) artikelen brengt verzendenkosten met zich mee.',
			label: 'Bereken verzendkosten'
		},
		subtotal: 'Subtotaal',
		tax: 'BTW',
		terms: {
			preregistration:
				"Door op de knop 'Verlanglijst Opslaan' te klikken maak ik een verlanglijst aan en ga ik akkoord met de {termsConditions} en bevestig ik dat ik de {privacyPolicy} heb gelezen. Door te accepteren, ga ik ook akkoord met de algemene voorwaarden van onze partner {paymentProvider}. Wanneer ik bij mijn bestelling ook de merchandise-artikelen heb geselecteerd, verklaar ik ook akkoord te gaan met de {merchandise}",
			default:
				"Door op de knop 'Ik ben klaar om te bestellen' te klikken maak ik een order aan en ga ik akkoord met de {termsConditions} en bevestig ik dat ik de {privacyPolicy} heb gelezen. Door te accepteren, ga ik ook akkoord met de algemene voorwaarden van onze partner {paymentProvider}. Wanneer ik bij mijn bestelling ook de merchandise-artikelen heb geselecteerd, verklaar ik ook akkoord te gaan met de {merchandise}"
		},
		title: 'Je winkelwagen',
		total: 'Totaal',
		tourist_tax: {
			lead: 'Toeristenbelasting is een bedrag dat door de gemeente wordt gevraagd. In dit geval is dat € 5,- per campingbezoeker',
			title: 'Toeristenbelasting'
		},
		upsell: {
			cta: 'Meer Producten',
			lead: 'Voeg deze producten toe aan je winkelmandje',
			title: 'Anderen kochten ook:'
		},
		voucher: {
			add: 'Voucher -code toevoegen',
			invalid: 'Deze voucher -code is ongeldig',
			used: 'Deze voucher -code is al gebruikt',
			redeem: 'Voucher inwisselen',
			success: 'Voucher heeft succesvol ingewisseld!'
		}
	},
	error: {
		accommodation_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd. Deze accommodatie is uitverkocht.Mensen voltooien nu hun boeking. Als de betalingen niet worden bevestigd, zal dit pakket weer beschikbaar zijn. ',
		accommodation_ticket_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd.De tickets zijn momenteel niet beschikbaar.Als betalingen niet worden bevestigd, is dit ticket weer beschikbaar. ',
		voucher_invald: 'Deze voucher-code is ongeldig',
		voucher_user: 'Deze voucher-code is al gebruikt',
		voucher_redeem_error: 'Er is een fout opgetreden bij het verzilveren van de voucher. Probeer het opnieuw. ',
		form_covid_not_accepted: 'U bent het nog niet eens met de terugbetalingsvoorwaarden van COVID.',
		form_has_errors: 'Corrigeer de fouten in het formulier om uw bestelling te voltooien.',
		form_incomplete: 'Vul alle vereiste velden in *',
		form_no_country_selected: 'U hebt nog geen land geselecteerd om de verzendkosten te berekenen.',
		form_no_insurance_choice: 'Geef aan of u een boekingsbescherming voor uw bestelling wilt toevoegen.',
		form_terms_not_accepted: 'Accepteer de algemene voorwaarden om uw bestelling te voltooien.',
		generic:
			'Probeer het opnieuw.Vernieuw deze pagina en voeg, indien nodig, lid van de wachtrij om opnieuw toegang te krijgen tot de ticketwinkel. ',
		invalid_request: 'Er is een ongeldig verzoek gedaan.Probeer het opnieuw. ',
		no_config_found: 'Een OSS -configuratie ontbreekt.Neem contact op met de ondersteuning. ',
		order_lookup_failed:
			'Er is een fout opgetreden bij het opzoeken van uw bestelling. Er ging iets mis aan onze zijde. Probeer het later opnieuw',
		order_lookup_invalid:
			'Uw bestelnummer lijkt ongeldig, kopieer het juiste nummer uit de e-mail die u heeft ontvangen.',
		order_lookup_no_order:
			'We kunnen geen bestelling vinden met dat bestelnummer. Kopieer het juiste bestelnummer uit van de e-mail die u heeft ontvangen.',
		page_not_found: 'Pagina niet gevonden.',
		product_sold_out: 'Dit product is uitverkocht.',
		shipping_unavailable: 'Verzending is momenteel niet beschikbaar voor uw locatie.',
		undefined: 'Er is een onbekende fout opgetreden.',
		code: {
			0: '--modal-closed--',
			1000: {
				title: 'Oeps...',
				message: 'Er ging iets mis.Probeer het opnieuw. '
			},
			2000: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			2001: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			2002: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: 'DOORGAAN'
			},
			3000: {
				title: 'Registratiefout',
				message:
					'Er is geen registratie gekoppeld aan dit {Brand} -account. Neem contact op met info@q-dance.com voor meer informatie.'
			},
			3001: {
				title: 'Registratiefout',
				message: 'Er is al een bestelling bij dit account geplaatst'
			},
			3002: {
				title: 'Registratiefout',
				message: 'Registratie is gesloten.'
			},
			3003: {
				title: 'Niet toegestaan',
				message: 'De winkel is nog niet open voor u.Controleer uw tijdslot en probeer het opnieuw. '
			},
			3005: {
				title: 'Oeps ... je bent al opgedragen',
				message: 'U kunt maar één lidmaatschap per account kopen.'
			},
			4000: {
				title: 'De winkel is gesloten'
			},
			4001: {
				title: 'Bedankt',
				message: 'Uw verlanglijst is met succes opgeslagen!'
			},
			4002: {
				title: 'Upgrade -fout',
				message:
					'Bestelling al verwerkt of niet gevonden.Als u denkt dat dit een fout is, klikt u opnieuw op de e -maillink. '
			},
			4003: {
				title: 'Upgrade -fout',
				message:
					'Bestelling al verwerkt of niet gevonden.Als u denkt dat dit een fout is, klikt u opnieuw op de e -maillink. '
			},
			4004: {
				title: "Time's up",
				message:
					'Vanwege zwaar verkeer kunt u slechts een beperkte tijd doorbladeren.U kunt de winkel opnieuw binnenkomen door op de link in uw e-mail te klikken. ',
				label_cancel: false
			},
			4005: {
				title: 'Geen geldige bestelling ID',
				message:
					'Bestelling al verwerkt of niet gevonden.Als u denkt dat dit een fout is, klikt u opnieuw op de e -maillink. '
			},
			4006: {
				title: 'Geen geldige toegangs -ID',
				message:
					'U hebt een geldige toegangs -ID nodig om de winkel in te gaan.Probeer opnieuw op de link in de e -mail te klikken. '
			},
			5000: {
				title: 'Product uitverkocht'
			},
			5001: {
				title: 'Product niet gevonden'
			},
			5002: {
				title: 'Geen reispakket (s) gevonden',
				message: 'Maak een andere selectie'
			},
			5003: {
				title: 'Verzending niet beschikbaar',
				message:
					'De geselecteerde producten zijn/zijn automatisch uit uw winkelwagen verwijderd.Helaas is het niet mogelijk om merchandise naar het land te verzenden dat u hebt geselecteerd. <br> Neem voor vragen contact met ons op <a href="mailto:merchandise@q-dance.com"> merchandise@q-dance.com </A> '
			},
			6000: {
				title: 'Verander registratie',
				message:
					'Please note that you need to reselect your type of registration (Travel & Stay Packages or ticket only) to enter the member sale. Please complete your registration again at the next step.',
				label_cancel: 'Annuleren'
			},
			6001: {
				title: 'Item verwijderen',
				message: 'Weet u zeker dat u dit item wilt verwijderen?',
				label_cancel: 'Annuleren'
			},
			6002: {
				title: "Time's up",
				message:
					'Het lijkt erop dat het te veel tijd heeft geduurd om je bestelling te voltooien. Maak je geen zorgen, je kunt het opnieuw proberen door op de onderstaande knop te klikken. Zorg ervoor dat je je bestelling binnen 7 minuten voltooit!',
				label_cancel: false,
				label_confirm: 'Probeer het opnieuw'
			},
			6003: {
				title: 'Geen ticket (s) geselecteerd',
				message:
					'Uw bestelling bevat een accommodatie zonder toegangskaarten.Houd er rekening mee dat de ingang van {festivalid} alleen mogelijk is met een toegangsticket.Weet u zeker dat u wilt doorgaan met uw aankoop? ',
				label_cancel: false,
				label_confirm: 'Doorgaan'
			},
			6004: {
				title: 'Welkom bij de {Brand} Shop',
				message: 'Login / maak een account aan om te beginnen.',
				label_cancel: false,
				label_confirm: 'login'
			},
			6005: {
				title: 'Dediqated Lid Sale',
				message: '<p> U hebt een toegewijde lidmaatschap nodig om deze winkel te betreden. </p>',
				label_cancel: false,
				label_confirm: 'Winkel'
			},
			6010: {
				title: 'Weet je het zeker?',
				message: '<p> weet je zeker dat je je bestelling wilt annuleren? </p>',
				label_cancel: 'Nee',
				label_confirm: 'Ja'
			},
			7000: {
				title: 'Aandacht',
				message:
					'De Travel & Stay Lid -verkoop begint op 29 februari.Als u er toegang aan wilt krijgen, moet u uw registratie wijzigen om te reizen en te blijven.Alle resterende pakketten zullen ook te koop zijn tijdens de ticketlidverkoop op 7 maart. '
			},
			8000: {
				title: 'Aandacht',
				message: ''
			},
			8001: {
				title: 'Aandacht',
				message: '',
				label_cancel: false
			},
			8002: {
				title: 'Er is een fout opgetreden',
				message: '',
				label_cancel: false
			},
			8003: {
				title: '',
				message: '',
				label_cancel: 'DOORGAAN'
			}
		}
	},
	form: {
		address_information: 'Adresinformatie',
		birthday: 'Geboortedatum',
		city: 'Stad',
		country: {
			label: 'Land'
		},
		countrycode: {
			label: 'Landcode'
		},
		day: 'Dag',
		email: 'E-mailadres',
		extension: 'Toevoeging',
		filter: 'Kies een filter',
		filter_explain: 'Zoek wat u nodig hebt door een van de onderstaande categorieën te filteren.',
		firstname: 'Voornaam',
		gender: {
			female: 'Vrouw',
			label: 'Geslacht',
			male: 'Man',
			other: 'Ander'
		},
		lastname: 'Achternaam',
		month: 'Maand',
		personal_information: 'Persoonlijke informatie',
		phonenumber: 'Telefoonnummer',
		postalcode: 'Postcode',
		select: 'Een selectie maken',
		shipping_information: 'Verzendinformatie',
		state: 'Staat',
		select_arrival_date: 'Selecteer aankomstdatum',
		streetname: 'Straatnaam',
		streetnumber: 'Straatnummer',
		year: 'Jaar'
	},
	label: {
		back: 'Terug',
		buy: 'Kopen',
		cancel: 'Annuleren',
		cart: {
			add: 'Toevoegen',
			added: 'Toegevoegd',
			add_to_cart: 'Toevoegen aan winkelwagen',
			add_to_wishlist: 'Toevoegen aan verlanglijst',
			add_to_selection: 'Toevoegen aan selectie',
			add_x_to_cart: 'Voeg {x} tickets toe aan winkelwagen',
			add_x_to_wishlist: 'Voeg {x} tickets toe aan je verlanglijstje',
			cart: 'Winkelwagen',
			complete_payment: 'Volledige betaling',
			complete_order: 'Complete bestelling',
			continue_shopping: 'Blijf winkelen',
			order: 'Klaar om te bestellen',
			remove: 'Verwijderen',
			remove_from_cart: 'Verwijderen uit winkelwagen',
			remove_from_wishlist: 'Verwijderen uit verlanglijstje',
			replace_item: 'Vervangen',
			pay: 'Betalen',
			save: 'Pre-registratie opslaan',
			sold_out: 'Dit product is uitverkocht',
			wishlist: 'Verlanglijst'
		},
		clear: 'Leegmaken',
		close: 'Sluiten',
		complete_your_experience: 'Maak je ervaring compleet',
		confirm: 'Bevestig',
		continue: 'Ga Verder',
		continue_shopping: 'Verder winkelen',
		filter: 'filter',
		finalize: 'Voltooi je bestelling',
		find_order: 'Vind mijn bestelling',
		extras: "extra's",
		in_stock: 'Op voorraad',
		including: 'Including',
		go_to_checkout: 'Ga naar het afrekenen',
		go_to_wishlist: 'Ga naar verlanglijst',
		merchandise: 'Merchandise',
		more_info: 'Meer info',
		navigation: {
			back: 'Ga terug',
			checkout: 'Ga naar het afrekenen',
			next: 'Volgende',
			overview: 'Overzicht',
			previous: 'Vorig',
			wishlist: 'Ga naar de verlanglijst'
		},
		newsletter_signup: 'Meld u aan voor de nieuwsbrief',
		no: 'Nee',
		no_items: 'Geen producten',
		order: 'Bestellen',
		order_number: 'Jouw ordernummer',
		reset: 'reset',
		return_to_homepage: 'Keer terug naar de startpagina',
		sold_out: 'Uitverkocht',
		shop: {
			members_sale: 'Ticketshop',
			preregistration_sale: 'Verlanglijstje',
			regular_sale: 'Ticketshop',
			travel_sale: 'Campingticket upgradesp',
			wishlist: 'Verlanglijstje'
		},
		ticket: 'tickets',
		transportation: 'transport',
		travel: 'reis',
		try_again: 'Probeer het opnieuw',
		update: 'update',
		yes: 'Ja'
	},
	included: 'Inclusief',
	loading: 'Laden ...',
	loading_one_moment: 'Eén moment alsjeblieft ...',
	modal: {
		save_time: {
			body: 'logNuInEnBespaarTijdTijdensHetAfrekenen',
			cta: 'Login van register',
			title: 'bespaarTijd'
		}
	},
	page: {
		checkout: {
			lead: 'U hebt 5 minuten om uw betaling te voltooien.',
			title: 'Selecteer uw betaalmethode'
		},
		compare_transport: {
			lead: '',
			title: 'Georganiseerde bus transport'
		},
		extras: {
			lead: 'Selecteer uw aanvullende producten zoals parkeerkaarten, kluisjes en andere ervaringen.',
			title: "Extra's en andere ervaringen"
		},
		home: {
			lead: 'Welkom, kies een evenement om te beginnen.',
			title: '{Brand} One Stop Shop'
		},
		membership: {
			disclaimer_step:
				'The DEDIQATED Membership is a recurring membership. You will be charged for 1-year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			membership_includes: 'Membership includes',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
			disclaimer_checkout:
				'You are about to commit to a 1-year Membership. You agree that Q-dance will charge you for 1 year in advance. Memberships will automatically renew after the expiration date, unless you cancel your subscription. More information on the terms & conditions can be found <a href="https://www.q-dance.com/en/static/terms-and-conditions-dediqated-membership" title="Terms and conditions Dediqated membership" target="_blank">here</a>',
			lead: '',
			title: 'MEMBERSHIP'
		},
		membership_monthly: {
			disclaimer_checkout:
				'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
			disclaimer_step:
				'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
			disclaimer_voucher:
				'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
			membership_includes: 'Membership omvat',
			lead: '',
			title: 'MEMBERSHIP'
		},
		merchandise: {
			lead: 'Selecteer uw merchandise.',
			one_size: 'Eén maat past alles',
			title: 'Merchandise'
		},
		payment_status: {
			canceled: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling geannuleerd'
			},
			completed: {
				lead: '',
				message:
					'Bedankt voor uw bestelling. We zijn momenteel uw bestelling en betaling verwerken. Dit is geen bevestiging! Uw bestelling wordt bevestigd nadat u een bevestigings-e-mail hebt ontvangen. Als u geen bevestigings-e-mail binnen 2 ontvangtUren, controleer uw bankrekening of creditcard om te zien of de betaling succesvol was.',
				title: 'Bedankt'
			},
			error: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling mislukt'
			},
			failed: {
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.',
				title: 'Betaling mislukt'
			},
			pending: {
				lead: '',
				message:
					"<p> Bedankt voor uw bestelling. We wachten nog steeds op de definitieve bevestiging van uw betaling door de betalingsprovider. </p> <p> Maak je geen zorgen, normaal gesproken krijgen we de bevestiging binnen een paar minuten. </p> <p> <u> Dit zal hierna gebeuren: </u> </p> <ul> <li> Uw bestelling en betaling worden verwerkt </li> <li> U ontvangt een bevestigingse-e-mail </li> <li> U ontvangt een trackingcode zodra we uw bestelling verzenden (indien van toepassing) </li> </ul> <br /> <br /> <p> Als het geld wordt afgetrokken van uw bankrekeningEn u krijgt geen bevestiging binnen 2 uur, neem contact op met <a target='_blank' href='https://www.justleep.it/'> Blaep klantenondersteuning </a>. </p> <p>U kunt ook contact met hen opnemen voor andere vragen over uw bestelling. </p> ",
				title: 'Betaling is in behandeling'
			},
			newsletter:
				'Meld u aan voor de nieuwsbrief en wees de eerste die op de hoogte is van belangrijke aankondigingen.',
			payment_provider: {
				adyen: {
					message:
						"Als u vragen heeft over uw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://www.justleep.it/'> Blaap klantenservice </a>."
				},
				paylogic: {
					message:
						"Als u vragen heeft over uw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://customerservice.paylogic.com/'> Paylogic klantenservice </a>."
				}
			}
		},
		shop: {
			experience_prebuilder: {
				form: ['Ik ben geïnteresseerd in', 'ik zal voorbij reizen', 'Hoeveel personen?'],
				lead: "Klik op 'Tickets Only' om tickets te bestellen. Gaat u voor de alles-in-één ervaring inclusief accommodaties? Klik op 'Travel & Stay-pakketten' voor alle reis- en accommodatiepakketten.",
				title: 'Creëer uw ervaring',
				preregistration: {
					lead: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
					title: '{festival} wishlist'
				}
			},
			experience_builder: {
				choose: 'Kies uw',
				select: 'Selecteer uw',
				wishlist: {
					title: '{festival} wishlist',
					lead: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.'
				},
				extras: {
					label: "Extra's en andere ervaringen",
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				lead: 'Welke producten zou u aan uw winkelwagen willen toevoegen?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				ticket: {
					label: '{Event} tickets',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				title: 'Je winkelwagen',
				transportation: {
					label: 'Georganiseerd bustransport',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				travel: {
					label: 'travel & Stay Packages',
					nights: '{n} nacht | {n} nachten',
					persons: '{n} persoon | {n} personen',
					remove: 'Verwijderen',
					update: 'Update boeken'
				},
				preregistration: {
					save: {
						lead: "During the {festival} sale, you can add your complete wishlist to your shopping cart. By doing so, you can immediately order all your preferred products at once. Keep in mind that certain products might already be sold out as the wishlist doesn’t guarantee you of any products.<br/><br/>It’s possible to make changes to your wishlist as often as you want during the registration period. The registration period ends on February 28th at 23:59 (CET). <br/><br/>Questions about your registration: <a href='mailto:info@q-dance.com'>info@q-dance.com</a>",
						title: 'Wishlist completed?'
					}
				}
			},
			lead: 'Alles wat je nodig hebt in één winkel.',
			title: 'Winkel'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'U kunt nu producten toevoegen zoals kluisjes, parkeerkaarten, reizen en accommodatiepakketten en merchandise aan uw winkelmandje.',
				title: "{Event} Extra's winkel"
			},
			experience_builder: {
				choose: 'Kies uw',
				select: 'Selecteer uw',
				preregistration: {
					title: '{festival} wishlist',
					lead: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.'
				},
				extras: {
					label: "Extra's en andere ervaringen",
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				lead: 'Welke producten zou u aan uw winkelwagen willen toevoegen?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				ticket: {
					label: '{Event} tickets',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				title: 'Je winkelwagen',
				transportation: {
					label: 'Georganiseerd bustransport',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				travel: {
					label: 'travel & Stay Packages',
					remove: 'Verwijderen',
					update: 'Update boeken'
				}
			},
			lead: 'Een overzicht van je vorige bestelling.',
			loading: {
				title: 'Uw vorige bestelling controleren ...',
				lead: 'We halen je vorige bestelling op. Refresh deze pagina niet, het laden van je vorige bestelling kan tot een minuut duren. '
			},
			no_order: {
				lead: 'Vul een ordernummer (het ordernummer van de hoofdboeker) in dat je hebt ontvangen in de e-mail . Zorg ervoor dat je bent ingelogd met hetzelfde account dat is gebruikt om de bestelling te plaatsen. ',
				title: 'Er zijn geen eerdere bestellingen gevonden.'
			},
			package: {
				addons: 'Je gekozen uitbreidingen in het winkelmandje',
				summary: 'Samenvatting van je gekozen pakket'
			},
			title: 'Jouw gekochte producten'
		},

		tickets: {
			filter_explain: 'Filter toegangskaarten',
			includes: 'Ticket inclusief',
			includes_fee_tax: 'Inclusief servicekosten en BTW',
			includes_fee: 'Inclusief servicekosten',
			lead: 'Selecteer uw toegangskaarten.',
			remove: 'Tickets verwijderen',
			select: 'Selecteer uw tickets',
			sold_out: 'Uitverkocht',
			sold_out_combo:
				'Tickets zijn uitverkocht!Een beperkt bedrag kan nog steeds beschikbaar zijn in combinatie met {link} ',
			title: 'Tickets',
			upsell_accommodations: 'Accommodatiepakketten zijn beschikbaar voor u om aan uw bestelling toe te voegen.',
			upsell_extras: 'Extra producten zijn beschikbaar voor u om aan uw bestelling toe te voegen.',
			upsell_travel: 'Reispakketten zijn beschikbaar voor u om aan uw bestelling toe te voegen.'
		},
		transportation: {
			add: 'Toevoegen',
			arrival: 'Aankomst',
			departure_time: '*Geschatte vertrektijden',
			disclaimer: '*Alle georganiseerde bussen gaan naar het',
			disclaimer2: 'festivalterrein.',
			fee: '*Excl.€ 5,- servicekosten per bestelling ',
			from: 'Van',
			lead: 'Selecteer uw georganiseerde bustransport.',
			netherlands: 'Nederland',
			remove: 'Verwijderen',
			select_other: 'Selecteer een andere optie.',
			sold_out: 'Uitverkocht',
			tickets: 'Buskaartjes',
			title: 'Georganiseerd bustransport',
			to: 'Naar'
		},
		travel: {
			accommodation_extras: "accommodatieExtra's",
			arrival_date_time: 'Aankomst dag en tijd',
			arrival_date: 'Aankomstdatum',
			available: 'Beschikbaar',
			category_extras: "Extra's",
			category_merchandise: 'Koopwaar',
			category_transfer: 'Transfers',
			create_package: 'Maak uw pakket',
			departure_date: 'Vertrekdatum',
			includes: 'Pakket omvat',
			lead: 'Selecteer uw reis- en verblijfpakketten.',
			make_selection: 'Maak je selectie',
			no_packages: 'Geen pakket (s) gevonden',
			no_ticket: 'Zonder ticket(s)',
			not_available: 'Niet beschikbaar',
			number_of_nights: 'Aantal nachten',
			package_price: 'Prijs voor reispakket',
			persons: 'Aantal personen',
			room_setup: 'Kies uw kamerinstelling',
			select_other: 'Selecteer een andere optie.',
			select_tickets: 'Selecteer je tickets',
			selected: 'Geselecteerd',
			sold_out: 'Uitverkocht',
			still_available: 'Pakketten nog beschikbaar',
			tickets: 'tickets',
			title: 'travel &StayPackages',
			try_again: 'Probeer het opnieuw',
			warning: {
				title: 'Warning',
				message:
					'Je hebt al tickets in je winkelwagentje.Weet u zeker dat u extra tickets aan uw reispakket wilt toevoegen? '
			}
		}
	},
	remaining: 'overig',
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},
	time: {
		hour: 'uur | uren ',
		hour_abbr: 'H',
		minute: 'minute | minuten',
		minute_abbr: 'min',
		second: 'seconde | seconden ',
		second_abbr: 'S'
	},
	wish_list: {
		checkout: {
			add_all: 'Alle items toevoegen',
			add_wishlist_products: 'Voeg eerst producten toe',
			calculate: 'Bereken verzendkosten',
			cta: 'Naar verlanglijstje',
			cta_alternative: 'Naar winkelwagen',
			price: 'Prijs',
			save: {
				lead: 'Je verlanglijstje is opgeslagen. Tijdens de kaartverkoop kun je inloggen met hetzelfde account om je verlanglijstje in je winkelmand te laden. Wanneer je een nieuwe verlanglijstje maakt, wordt de oude overschreven.',
				title: 'Verlanglijst opslaan'
			},
			shipping_costs: {
				explainer: 'Het verzenden van (sommige) artikelen brengt verzendenkosten met zich mee.',
				label: 'Bereken verzendkosten'
			},
			title: 'Uw verlanglijstje',
			total: 'Totaal'
		},
		experience_builder: {
			title: 'Uw verlanglijstje',
			lead: "U kunt nu producten toevoegen, zoals tickets, reizen en accommodatiepakketten, merchandise en extra's aan uw verlanglijstje. Uw verlanglijst kan in uw account worden opgeslagen."
		},
		lead: "Je kunt nu controleren of de producten van jouw verlanglijstje beschikbaar zijn. Zorg ervoor dat je ze aan je winkelmandje toevoegt als ze nog beschikbaar zijn. Product(en) uitverkocht? Je kunt een alternatief kiezen door op de knop 'vervangen' te klikken.",
		loading: {
			lead: 'We halen je opgeslagen verlanglijstje op. Refresh deze pagina niet, het laden van je verlanglijstje kan tot een minuut duren.',
			title: 'Je verlanglijstje wordt gecheckt'
		},
		preregistration: {
			title: 'Je verlanglijstje',
			lead: 'Maak een verlanglijstje en bespaar tijd tijdens de verkoop'
		},
		title: 'Je verlanglijstje'
	}
};
